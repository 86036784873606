<div id="loading-page" [class.move-out]="data.everythingLoaded">
  <div class="container">
    <h1 class="position-relative" style="font-size: 70px" i18n>Loading...</h1>
    <div id="enter-lionkat">
      <div [class.fade-out]="data.everythingLoaded || data.loadingError">
        <div class="lk-spinner hide-on-mobile"></div>
      </div>
      <div *ngIf="data.loadingError" style="opacity: 0" [class.fade-in]="data.loadingError">
        <p>{{data.errorText}}</p>
        <!--<p>{{data.errorText2}}</p>-->
      </div>
    </div>
  </div>
</div>
