import { Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import {ColorThemeEnum} from '../../classes/ColorTheme.enum';

@Component({
  selector: 'lionkat-app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent  {

  ColorTheme = ColorThemeEnum;

  constructor(public userService: UserService) { }

}
