<div id="outer-container">
  <div class="container">
    <div class="impressum-page">
      <button id="back-button" routerLink="/">
        <span class="material-icons">arrow_back</span>
      </button>

      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>Leon Kunzler<br/>
        Im Avelertal 47<br/>
        54295 Trier</p>

      <h2>Kontakt</h2>
      <p>Telefon: +49 (0) 1626394029<br/>
        E-Mail: leonremi97@gmail.com</p>

      <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
      </p>
    </div>
  </div>
</div>
<lionkat-bottom-bar></lionkat-bottom-bar>
