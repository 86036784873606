import {AfterViewInit, Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {UserService} from '../../services/user.service';
import {CookieService} from '../../services/cookie.service';
import {Sentence} from '../../classes/Sentence';
import {ApiService} from '../../services/api.service';
import {FavoriteFilterTypeEnum} from '../../classes/FavoriteFilterType.enum';
import {ColorThemeEnum} from '../../classes/ColorTheme.enum';
import {HSKLevelEnum} from '../../classes/HSKLevel.enum';
import {MandarinFontsEnum} from '../../classes/MandarinFonts.enum';
import {CookieNamesEnum} from '../../classes/CookieNames.enum';
import {LanguageEnum} from '../../classes/LanguageEnum';

@Component({
  selector: 'lionkat-practice-hsk',
  templateUrl: './practice-hsk.component.html',
  styleUrls: ['./practice-hsk.component.scss']
})
export class PracticeHskComponent implements OnInit, AfterViewInit {

  ColorTheme = ColorThemeEnum;
  HSKLevel = HSKLevelEnum;
  Languages = LanguageEnum;
  FavoriteFilters = FavoriteFilterTypeEnum;

  @ViewChild('hskValue')
  hskValue: ElementRef; // select for hsk value

  @ViewChild('favSelect')
  favSelect: ElementRef; // select for favorites

  @ViewChild('chineseSentence')
  chineseSentence: ElementRef;

  constructor(@Inject(LOCALE_ID) public locale: LanguageEnum,
              private api: ApiService,
              public dataService: DataService,
              public userService: UserService,
              public cookieService: CookieService) {
  }


  ngOnInit(): void {
/*    this.matomoTracker.setDoNotTrack(true);
        this.matomoTracker.setConsentGiven();
        this.matomoTracker.setCookieConsentGiven();*/

    if (!this.dataService.everythingLoaded) {
      this.api.getAllHSKSentences().subscribe({
        next: value => {
          this.initLionkat(value);
        },
        error: () => {
          const cachedData = JSON.parse(localStorage.getItem('hsk_sentences')) as Sentence[];
          if (cachedData !== null) {
            this.initLionkat(cachedData);
          } else {
            this.dataService.loadingError = true;
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    /*    this.favSelect.nativeElement.addEventListener('select', function() {
          this.selectionStart = this.selectionEnd;
        }, false);

        this.hskValue.nativeElement.addEventListener('select', function() {
          this.selectionStart = this.selectionEnd;
        }, false);*/
  }

  @HostListener('window:keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    if (this.dataService.listenToKeystrokes) {
      if (event.ctrlKey || event.altKey) {
        return;
      }
      switch (event.code) {
        case 'KeyD':
        case 'ArrowRight':
          this.nextSentence();
          break;
        case 'KeyA':
        case 'ArrowLeft':
          this.previousSentence();
          break;
        case 'KeyR':
          this.randomSentence();
          break;
        case 'KeyF':
          this.toggleFavorite();
          break;
        case 'Space':
          this.toggleTranslationVisibility();
          break;
      }
    }
  }


  async initLionkat(hskData: Sentence[]) {
    localStorage.removeItem('hsk_sentences');
    localStorage.setItem('hsk_sentences', JSON.stringify(hskData));
    this.dataService.hskSentences = hskData;
    this.dataService.filteredHskSentences = hskData;
    this.dataService.setDefaultValues();
    this.dataService.setDefaultSettings();
    this.checkAllCookies(); // If cookies exist, override default settings
    this.checkLocalStorageDataFavorites();
    this.initializeNotVisitedArray();
    this.dataService.everythingLoaded = true;

    await this.delay(1500);
    // removed loading screen from DOM
    this.dataService.hideLoadingScreen = true;
  }


  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  /**
   * Array with length of currently selected hsk filter.
   * All values except the first one are set to false.
   * Whenever a new sentence is seen, the array at the position of the sentence is set to true
   */
  initializeNotVisitedArray() {
    this.dataService.notVisitedIndices = [];
    for (let i = 1; i < this.dataService.filteredHskSentences.length; i++) {
      this.dataService.notVisitedIndices.push(i);
    }
  }

  applyFilter() {
    this.dataService.filteredHskSentences = this.dataService.hskSentences.filter(x => {
      switch (this.dataService.currentHskFilter) {
        case this.dataService.hskFilters[0]:
          return x.hsk === 1;
        case this.dataService.hskFilters[1]:
          return x.hsk === 2;
        case this.dataService.hskFilters[2]:
          return x.hsk === 3;
        case this.dataService.hskFilters[3]:
          return x.hsk === 4;
      }
    });
    this.dataService.filteredHskSentences = this.dataService.filteredHskSentences.filter(x => {
      switch (this.dataService.currentFavoriteFilter) {
        case FavoriteFilterTypeEnum.All:
          return true;
        case FavoriteFilterTypeEnum.OnlyFavorites:
          return this.userService.favorites.has(x.id);
        case FavoriteFilterTypeEnum.OnlyNoFavorites:
          return !this.userService.favorites.has(x.id);
        default:
          return true;
      }
    });

    // resetting values
    this.dataService.currentSentencePos = 0;
    this.initializeNotVisitedArray();
  }

  updateHskFilter(hskValue: string) {
    this.dataService.currentHskFilter = hskValue as HSKLevelEnum;
    this.applyFilter();
    this.dataService.updateCurrentSentenceIdAndCategory();
  }

  updateFavoriteFilter(favoriteFilter: string) {
    this.dataService.currentFavoriteFilter = favoriteFilter as FavoriteFilterTypeEnum;
    this.applyFilter();
    this.dataService.updateCurrentSentenceIdAndCategory();
  }

  /**
   * Adds or removes a sentence to the list of favorites which is saved locally in the browser cache.
   */
  toggleFavorite() {
    if (this.dataService.filteredHskSentences[this.dataService.currentSentencePos]) {
      if (this.userService.favorites.has(this.dataService.filteredHskSentences[this.dataService.currentSentencePos].id)) {
        this.userService.favorites.delete(this.dataService.filteredHskSentences[this.dataService.currentSentencePos].id);
        if (this.dataService.currentFavoriteFilter === FavoriteFilterTypeEnum.OnlyFavorites) {
          this.applyFilter();
        }
      } else {
        this.userService.favorites.add(this.dataService.filteredHskSentences[this.dataService.currentSentencePos].id);
        if (this.dataService.currentFavoriteFilter === FavoriteFilterTypeEnum.OnlyNoFavorites) {
          this.applyFilter();
        }
      }
      this.dataService.updateCurrentSentenceIdAndCategory();
      localStorage.setItem('favorites', JSON.stringify(Array.from(this.userService.favorites)));
    }
  }

  async previousSentence() {
    if (this.dataService.currentSentencePos !== 0) {
      this.dataService.currentSentencePos--;
    } else {
      this.dataService.currentSentencePos = this.dataService.filteredHskSentences.length - 1;
    }
    this.dataService.notVisitedIndices.splice(this.dataService.currentSentencePos, 1);
    this.dataService.showingTrans = false;
    this.cookieService.setCookie(CookieNamesEnum.currentSentenceId, this.dataService.filteredHskSentences[this.dataService.currentSentencePos].id);
  }

  async nextSentence() {
    if (this.dataService.currentSentencePos !== this.dataService.filteredHskSentences.length - 1) {
      this.dataService.currentSentencePos++;
    } else {
      this.dataService.currentSentencePos = 0;
      if (this.dataService.filteredHskSentences.length === 1) {
        return;
      }
    }
    this.dataService.notVisitedIndices.splice(this.dataService.currentSentencePos, 1);
    this.dataService.showingTrans = false;
    this.cookieService.setCookie(CookieNamesEnum.currentSentenceId, this.dataService.filteredHskSentences[this.dataService.currentSentencePos].id);
  }

  async randomSentence() {
    if (this.dataService.notVisitedIndices.length === 0) {
      this.initializeNotVisitedArray();
      this.dataService.currentSentencePos = 0;
    } else {
      const rand = Math.floor(Math.random() * (this.dataService.notVisitedIndices.length - 1));
      this.dataService.currentSentencePos = this.dataService.notVisitedIndices[rand];
      this.dataService.notVisitedIndices.splice(rand, 1);
    }
    this.dataService.showingTrans = false;
    this.cookieService.setCookie(CookieNamesEnum.currentSentenceId, this.dataService.filteredHskSentences[this.dataService.currentSentencePos].id);
  }

  toggleTranslationVisibility() {
    this.dataService.showingTrans = !this.dataService.showingTrans;
  }

  /**
   * Reads and sets all user data values from cookies.
   * If cookies don't exist, they are set with default values.
   */
  checkAllCookies() {
    // font
    const font = this.cookieService.getCookie(CookieNamesEnum.font);
    if (font !== '' && this.dataService.fonts.includes(font as MandarinFontsEnum)) {
      this.userService.font = font as MandarinFontsEnum;
    } else {
      this.cookieService.setCookie(CookieNamesEnum.font, MandarinFontsEnum.NotoSerif);
    }

    // color theme
    const colorTheme = this.cookieService.getCookie(CookieNamesEnum.colorTheme);
    if (colorTheme !== '' && this.dataService.colorThemes.includes(colorTheme as ColorThemeEnum)) {
      this.userService.colorTheme = colorTheme as ColorThemeEnum;
    } else {
      this.cookieService.setCookie(CookieNamesEnum.colorTheme, ColorThemeEnum.Dark);
    }

    // alwaysShowTranslation
    const showingTranslation = this.cookieService.getCookie(CookieNamesEnum.showTranslation);
    if (showingTranslation !== '' && (showingTranslation === 'true' || showingTranslation === 'false')) {
      this.userService.alwaysShowTrans = (showingTranslation === 'true');
    } else {
      this.cookieService.setCookie(CookieNamesEnum.showTranslation, false);
    }

    // showTooltips
    const showTooltips = this.cookieService.getCookie(CookieNamesEnum.showTooltips);
    if (showTooltips !== '' && (showTooltips === 'true' || showTooltips === 'false')) {
      this.userService.showTooltips = (showTooltips === 'true');
    } else {
      this.cookieService.setCookie(CookieNamesEnum.showTooltips, true);
    }

    // saveCurrentSentenceId, hskFilter
    const saveCurrentSentenceId = this.cookieService.getCookie(CookieNamesEnum.saveCurrentSentenceId);
    if (saveCurrentSentenceId !== '' && (saveCurrentSentenceId === 'true' || saveCurrentSentenceId === 'false')) {
      this.userService.saveCurrentSentenceId = (saveCurrentSentenceId === 'true');
      if (this.userService.saveCurrentSentenceId) {
        // hskFilter
        const hskFilter = this.cookieService.getCookie(CookieNamesEnum.currentHSKFilter);
        if (hskFilter && this.dataService.hskFilters.includes(hskFilter as HSKLevelEnum)) {
          this.dataService.currentHskFilter = hskFilter as HSKLevelEnum;
          this.applyFilter();
        }
        // sentenceId
        const currentSentenceId = +this.cookieService.getCookie(CookieNamesEnum.currentSentenceId);
        this.dataService.currentSentencePos = this.dataService.filteredHskSentences.findIndex(x => x.id === currentSentenceId);
        if (this.dataService.currentSentencePos === -1) {
          this.dataService.currentSentencePos = 0;
        }
      }
    } else {
      this.cookieService.setCookie(CookieNamesEnum.saveCurrentSentenceId, true);
    }
  }

  // todo maybe can be optimised to elimate error and improve runtime
  checkLocalStorageDataFavorites() {
    // reads favorites and rids it of wrong entries
    const favorites = localStorage.getItem('favorites');
    try {
      if (favorites !== null) {
        for (const id of JSON.parse(favorites)) {
          this.dataService.hskSentences.forEach(s => {
            if (s.id === id) {
              this.userService.favorites.add(id);
            }
          });
        }
        localStorage.setItem('favorites', JSON.stringify(Array.from(this.userService.favorites)));
      }
    } catch (e) {
      // if parsing doesnt work, eg a char in favorites instead of numbers
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
