import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {BugDialogComponent} from './components/bug-dialog/bug-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AboutComponent} from './components/about/about.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ResetSettingsDialogComponent} from './components/reset-settings-dialog/reset-settings-dialog.component';
import {MenuComponent} from './components/menu/menu.component';
import {LoadingScreenComponent} from './components/loading-screen/loading-screen.component';
import {KeybindingsDialogComponent} from './components/keybindings-dialog/keybindings-dialog.component';
import {FirstVisitPageComponent} from './components/first-visit-page/first-visit-page.component';
import {PracticeHskComponent} from './components/practice-hsk/practice-hsk.component';
import {BottomBarComponent} from './components/bottom-bar/bottom-bar.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {PracticeTopicsComponent} from './components/practice-topics/practice-topics.component';
import { ImpressumComponent } from './components/impressum/impressum.component';

@NgModule({
  declarations: [
    AppComponent,
    PracticeHskComponent,
    BugDialogComponent,
    AboutComponent,
    ResetSettingsDialogComponent,
    MenuComponent,
    LoadingScreenComponent,
    KeybindingsDialogComponent,
    FirstVisitPageComponent,
    BottomBarComponent,
    TopBarComponent,
    PracticeTopicsComponent,
    ImpressumComponent,
  ],
  imports: [
    BrowserModule,
/*    NgxMatomoTrackerModule.forRoot({
      siteId: 'YOUR_MATOMO_SITE_ID', // your Matomo's site ID (find it in your Matomo's settings)
      trackerUrl: 'YOUR_MATOMO_SERVER_URL', // your matomo server root url
    }),
    NgxMatomoRouterModule,*/
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    FormsModule,
    AppRoutingModule,
    MatTooltipModule,
  ],
  providers: [{provide: LOCALE_ID, useValue: location.href.includes('/de') ? 'de' : 'en'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
