import {Injectable} from '@angular/core';
import {Sentence} from '../classes/Sentence';
import {UserService} from './user.service';
import {CookieService} from './cookie.service';
import {MatDialog} from '@angular/material/dialog';
import {FavoriteFilterTypeEnum} from '../classes/FavoriteFilterType.enum';
import {ColorThemeEnum} from '../classes/ColorTheme.enum';
import {HSKLevelEnum} from '../classes/HSKLevel.enum';
import {MandarinFontsEnum} from '../classes/MandarinFonts.enum';
import {CookieNamesEnum} from '../classes/CookieNames.enum';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  loadingError = false;
  errorText = $localize`Data could not be loaded. Please check your internet connection.`;

  notVisitedIndices = []; // if all sentences were seen, this gets reset to a list of all sentences
  currentSentencePos = 0; // array pos of exampleSentences
  fonts = Object.values(MandarinFontsEnum);
  colorThemes = Object.values(ColorThemeEnum);

  hskFilters = Object.values(HSKLevelEnum);
  currentHskFilter = HSKLevelEnum.HSK1;

  currentFavoriteFilter = FavoriteFilterTypeEnum.All;

  showingTrans = false;
  menu = false;
  everythingLoaded = false;
  hideLoadingScreen = false;
  listenToKeystrokes = true;

  hskSentences: Sentence[];
  filteredHskSentences: Sentence[];

  settingsReset = false;

  constructor(private userService: UserService,
              private cookieService: CookieService,
              public dialog: MatDialog) {
  }

  setDefaultValues() {
    this.currentFavoriteFilter = FavoriteFilterTypeEnum.All;
    this.userService.currentSentenceId = 0;
    this.userService.favorites = new Set<number>();
  }

  setDefaultSettings() {
    this.userService.colorTheme = ColorThemeEnum.Dark;
    this.userService.font = MandarinFontsEnum.NotoSerif;
    this.userService.alwaysShowTrans = false;
    this.userService.showTooltips = true;
    this.userService.saveCurrentSentenceId = true;
  }

  /**
   * Sets new cookies for current sentenceID and current HSK filter.
   */
  updateCurrentSentenceIdAndCategory() {
    if (this.filteredHskSentences[this.currentSentencePos]) {
      this.cookieService.setCookie(CookieNamesEnum.currentSentenceId, this.filteredHskSentences[this.currentSentencePos].id);
    }
    this.cookieService.setCookie(CookieNamesEnum.currentHSKFilter, this.currentHskFilter);
  }
}
