<button class="dialog-close-button" matDialogClose="">
  <span class="material-icons">
    clear
  </span>
</button>

<div *ngIf="step === BugDialogSteps.Overview">
  <h1 i18n>What do you want to tell us about?</h1>
  <div class="row mt-4">
    <div class="col-6 px-1">
      <button class="lk-button feedback-btn" (click)="step = BugDialogSteps.Sentence" i18n>Issue with the current sentence</button>
    </div>
    <div class="col-6 px-1">
      <button class="lk-button feedback-btn" (click)="step = BugDialogSteps.General" i18n>Other feedback</button>
    </div>
  </div>
</div>


<div *ngIf="step === BugDialogSteps.Sentence || step === BugDialogSteps.General">
  <button class="lk-button back-btn" (click)="backToMain()" i18n>
    <span class="material-icons">
      arrow_back
    </span>
  </button>

  <div *ngIf="step === BugDialogSteps.Sentence">
    <h1>Please specify your issue</h1>
    <label class="checkbox-label" i18n>
      Bad sentence
      <input class="form-check-input" type="radio" name="radio"
             (change)="onInputChange('bad')">
      <span class="checkmark"></span>
    </label>

    <label class="checkbox-label" i18n>
      Faulty sentence
      <input class="form-check-input" type="radio" name="radio"
             (change)="onInputChange('faulty')">
      <span class="checkmark"></span>
    </label>

    <label class="checkbox-label" i18n>
      Other
      <input class="form-check-input" type="radio" name="radio"
             (change)="onInputChange('other')">
      <span class="checkmark"></span>
    </label>
  </div>

  <div class="input-group">
    <p class="mt-2 mb-1" i18n>Description</p>
    <div class="input-group">
      <textarea [maxLength]="maxDescLen" class="form-control" rows="4" i18n-placeholder i18n-aria-label placeholder="Description" aria-label="description"
                [(ngModel)]="feedback.description"></textarea>
    </div>
  </div>

  <p class="error-text" *ngFor="let x of errorMessages">{{x}}</p>
  <button *ngIf="!loading" class="lk-button mt-2 w-100" (click)="submitFeedback()" i18n>Submit</button>
  <div class="lk-spinner" style="margin-top: 24px;" *ngIf="loading"></div>
</div>

<div *ngIf="step === BugDialogSteps.Thanks">
  <p i18n>Thanks for submitting your feedback!</p>
</div>

<div *ngIf="step === BugDialogSteps.Error">
  <h1 i18n>Unfortunately your feedback could not be submitted</h1>
  <hr>
  <p>"{{serverErrorMessage}}"</p>
</div>
