<lionkat-loading-screen *ngIf="!dataService.hideLoadingScreen"></lionkat-loading-screen>

<lionkat-top-bar [withMenuButton]="true" text="LionKat"></lionkat-top-bar>

<div *ngIf="dataService.everythingLoaded" id="outer-container"
     [class.lightmode]="userService.colorTheme === ColorTheme.Light"
     [class.darkmode]="userService.colorTheme === ColorTheme.Dark"
     [class.blue-theme]="userService.colorTheme === ColorTheme.Blue"
     [class.red-theme]="userService.colorTheme === ColorTheme.Red">

  <div id="main-container">
    <!-- HSK filter -->
    <select class="filter-dropdown mr-2"
            #hskValue
            aria-label="hsk filter"
            (change)="updateHskFilter(hskValue.value)">
      <option *ngFor="let x of dataService.hskFilters" [selected]="x === dataService.currentHskFilter" value="{{x}}"
              [disabled]="x === HSKLevel.HSK3 || x === HSKLevel.HSK4">{{x}}</option>
    </select>

    <!-- Favorites filter -->
    <select class="filter-dropdown"
            #favSelect
            aria-label="favorite filter"
            (change)="updateFavoriteFilter(favSelect.value)">
      <option [value]="FavoriteFilters.All" i18n>Show all</option>
      <option [value]="FavoriteFilters.OnlyFavorites" i18n>Only favorites</option>
      <option [value]="FavoriteFilters.OnlyNoFavorites" i18n>Only non-favorites</option>
    </select>

    <div *ngIf="dataService.filteredHskSentences[dataService.currentSentencePos] else noDataCashed">
      <div id="chinese-sentence-box">
        <h2 #chineseSentence id="chineseSentence"
            [className]="userService.font + ' text-center'"
            *ngIf="dataService.filteredHskSentences[dataService.currentSentencePos]">{{dataService.filteredHskSentences[dataService.currentSentencePos].sentence_ch}}</h2>
      </div>
      <div id="sentence-information">

        <p i18n>Word:
          <span matTooltip="{{dataService.filteredHskSentences[dataService.currentSentencePos].word_pin}}:&#13;&#13;{{locale === Languages.en ? dataService.filteredHskSentences[dataService.currentSentencePos].word_en : dataService.filteredHskSentences[dataService.currentSentencePos].word_de}}"
                class="hsk-char"
                matTooltipClass="multiline-tooltip"
                [matTooltipDisabled]="!userService.showTooltips">{{dataService.filteredHskSentences[dataService.currentSentencePos].word_ch}}
          </span>,
          HSK: {{dataService.filteredHskSentences[dataService.currentSentencePos].hsk}},
          Sentence {{dataService.currentSentencePos + 1}}/{{dataService.filteredHskSentences.length}}</p>
        <span *ngIf="!userService.favorites.has(dataService.filteredHskSentences[dataService.currentSentencePos].id)"
              (click)="toggleFavorite()" class="material-icons favorite-button"
              i18n-matTooltip
              matTooltip="Add to favorites">
              favorite_border
            </span>
        <span *ngIf="userService.favorites.has(dataService.filteredHskSentences[dataService.currentSentencePos].id)"
              (click)="toggleFavorite()" class="material-icons favorite-button"
              i18n-matTooltip
              matTooltip="Remove from favorites">
              favorite
            </span>
      </div>

      <div class="row justify-content-center align-items-center mt-2 mt-md-4">
        <div class="col-4">
          <button (click)="previousSentence()" class="lk-button" id="l-button">
            <span class="material-icons">
              arrow_back
            </span>
          </button>
        </div>

        <div class="col-4">
          <button (click)="randomSentence()" class="lk-button" id="random-button">
          <span class="material-icons">
            shuffle
          </span>
          </button>
        </div>

        <div class="col-4">
          <button (click)="nextSentence()" class="lk-button" id="r-button">
        <span class="material-icons">
          arrow_forward
        </span>
          </button>
        </div>
      </div>

      <button *ngIf="!userService.alwaysShowTrans && !dataService.showingTrans" class="lk-button mt-1"
              (click)="toggleTranslationVisibility()"
              id=translate-button>
        Translate
      </button>
      <button *ngIf="!userService.alwaysShowTrans && dataService.showingTrans" class="lk-button mt-1"
              (click)="toggleTranslationVisibility()"
              id=translate-button>
        Hide translation
      </button>

      <!-- Translations -->
      <div *ngIf="dataService.showingTrans || userService.alwaysShowTrans" id="translation-box" class="mt-3">
        <p>{{dataService.filteredHskSentences[dataService.currentSentencePos].sentence_pin}}</p>
        <p
          *ngIf="locale === Languages.en">{{dataService.filteredHskSentences[dataService.currentSentencePos].sentence_en}}</p>
        <p
          *ngIf="locale === Languages.de">{{dataService.filteredHskSentences[dataService.currentSentencePos].sentence_de}}</p>
      </div>

      <small *ngIf="locale === Languages.en">Sentences provided by <a target="_blank" href="https://www.pinhok.com/">Pinhok</a>.</small>
      <small *ngIf="locale === Languages.de">Sätze wurden bereitgestellt von <a target="_blank" href="https://www.pinhok.com/de/">Pinhok</a>.</small>
    </div>

    <ng-template #noDataCashed>
      <div id="chinese-sentence-box">
        <p id="chineseSentence" i18n>No sentence matches your filters.</p>
      </div>
    </ng-template>
  </div>
</div>
<lionkat-bottom-bar></lionkat-bottom-bar>
