export enum CookieNamesEnum{
  font = 'font',
  colorTheme = 'colorTheme',
  showTranslation = 'showTranslation',
  showTooltips = 'showTooltips',
  language = 'language',
  saveCurrentSentenceId = 'saveCurrentSentenceId',
  currentSentenceId = 'currentSentenceId',
  currentHSKFilter = 'currentHSKFilter',
  acceptedCookies = 'acceptedCookies'
}
