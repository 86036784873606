<button class="dialog-close-button" matDialogClose="">
  <span class="material-icons">
    clear
  </span>
</button>

<h1>Keybindings</h1>
<table class="table">
  <thead>
  <tr>
    <th scope="col" style="font-weight: bold" i18n>Action</th>
    <th scope="col" style="font-weight: bold" i18n>Key</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td i18n>Show or hide translation</td>
    <td i18n>Spacebar</td>
  </tr>
  <tr>
    <td i18n>Previous sentence</td>
    <td i18n>A / Left arrow key</td>
  </tr>
  <tr>
    <td i18n>Next sentence</td>
    <td i18n>D / Right arrow key</td>
  </tr>
  <tr>
    <td i18n>Random sentence</td>
    <td>R</td>
  </tr>
  </tbody>
</table>
