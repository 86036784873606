import {Injectable} from '@angular/core';
import {ColorThemeEnum} from '../classes/ColorTheme.enum';
import {MandarinFontsEnum} from '../classes/MandarinFonts.enum';

@Injectable({
  providedIn: 'root'
})
/**
 * This service saves specific user data. This data is saved in cookies rather than on a server.
 * Changing a value will update the cookie aswell.
 */
export class UserService {

  allowTracking = false;

  colorTheme: ColorThemeEnum;
  font: MandarinFontsEnum;
  alwaysShowTrans: boolean;
  showTooltips: boolean;
  saveCurrentSentenceId: boolean;
  currentSentenceId: number;
  favorites: Set<number>; // sentence ids

  constructor() {
  }
}
