import {Component, Inject, LOCALE_ID} from '@angular/core';
import {UserService} from '../../services/user.service';
import {DataService} from '../../services/data.service';
import {ResetSettingsDialogComponent} from '../reset-settings-dialog/reset-settings-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CookieService} from '../../services/cookie.service';
import {MandarinFontsEnum} from '../../classes/MandarinFonts.enum';
import {ColorThemeEnum} from '../../classes/ColorTheme.enum';
import {CookieNamesEnum} from '../../classes/CookieNames.enum';
import {LanguageEnum} from '../../classes/LanguageEnum';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'lionkat-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  MandarinFonts = MandarinFontsEnum;
  ColorThemes = ColorThemeEnum;
  Languages = LanguageEnum;

  constructor(@Inject(LOCALE_ID) public locale: LanguageEnum,
              public userService: UserService,
              public data: DataService,
              private dialog: MatDialog,
              private cookieService: CookieService) {
  }

  get online() {
    return navigator.onLine;
  }

  toggleMenu() {
    this.data.settingsReset = false;
    this.data.menu = !this.data.menu;
    this.data.listenToKeystrokes = !this.data.listenToKeystrokes;
  }

  openResetSettingsDialog() {
    const dialogRef = this.dialog.open(ResetSettingsDialogComponent, {panelClass: 'larger-mat-dialog'});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.cookieService.deleteAllSettingsCookies();
        this.data.setDefaultSettings();
        this.data.settingsReset = true;
      }
    });
  }

  /**
   * Changes the current font and saves it in cookie.
   * @param font The new font.
   */
  changeFont(font: string) {
    this.userService.font = font as MandarinFontsEnum;
    this.cookieService.setCookie(CookieNamesEnum.font, font);
  }

  changeLanguage(lang: LanguageEnum) {
    if (!navigator.onLine) {
      alert($localize`Changing language requires reloading the page. It seems that you are currently not connected to the internet.
       Please reconnect to be able to change the app language.`);
      return;
    }

    this.cookieService.setCookie(CookieNamesEnum.showTooltips, lang);

    if (lang === LanguageEnum.en && this.locale === LanguageEnum.de) {
      // @ts-ignore
      window.location = 'https://www.lionkat.app/en';
    }
    if (lang === LanguageEnum.de && this.locale === LanguageEnum.en) {
      // @ts-ignore
      window.location = 'https://www.lionkat.app/de';
    }
  }

  toggleShowTooltips() {
    this.userService.showTooltips = !this.userService.showTooltips;
    this.cookieService.setCookie(CookieNamesEnum.showTooltips, this.userService.showTooltips);
  }

  toggleSaveCurrentSentenceId() {
    this.userService.saveCurrentSentenceId = !this.userService.saveCurrentSentenceId;
    this.data.updateCurrentSentenceIdAndCategory();
    this.cookieService.setCookie(CookieNamesEnum.saveCurrentSentenceId, this.userService.saveCurrentSentenceId);
  }

  toggleShowTranslation() {
    this.userService.alwaysShowTrans = !this.userService.alwaysShowTrans;
    this.cookieService.setCookie(CookieNamesEnum.showTranslation, this.userService.alwaysShowTrans);
  }
}
