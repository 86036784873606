import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'lionkat-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent  {

  @Input()
  withMenuButton = false;

  @Input()
  text = $localize`Text not set`;

  constructor(public utility: UtilityService) {
  }
}
