import { Component } from '@angular/core';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'lionkat-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent {

  constructor(public data: DataService) { }
}
