<div id="outer-container"
     [class.lightmode]="userService.colorTheme === ColorTheme.Light"
     [class.darkmode]="userService.colorTheme === ColorTheme.Dark"
     [class.blue-theme]="userService.colorTheme === ColorTheme.Blue"
     [class.red-theme]="userService.colorTheme === ColorTheme.Red">
  <div class="container">
    <div id="about-page">
      <button id="back-button" routerLink="/"><span class="material-icons">arrow_back</span></button>
      <h1 class="text-center mb-4" i18n>Welcome to LionKat!</h1>
      <p class="text-center" i18n>LionKat is a project by <a href="https://www.leonkunzler.de">Leon Kunzler</a> and Katharina Diederichs.<br>
        Our goal is to offer a convenient and fun way to practice Chinese without the need of creating an
        account or paying money.</p>
      <hr>
      <h2 i18n>
        Some aspects of LionKat:
      </h2>
      <ul>
        <li><b>Cross-platform</b><br>
          As LionKat is a web application, it runs on all modern browsers.
        </li>
        <hr>
        <li i18n><b>Usable offline</b><br>
          In the lionKat settings you can enable offline use. It saves all website data in your browser cache. This
          allows you to use the app while being offline. Disable offline use again to remove the data from your device.
        </li>
        <hr>
        <li i18n><b>Free to use</b><br>
          LionKat requires no account and all features are available to everyone.
        </li>
      </ul>
      <hr>
      <h2 i18n>Giving Feedback</h2>
      <p i18n>We appreciate all kinds of feedback.
        If you found a bug, a translation mistake or have general feedback, please tell us.
        To submit feedback, use the link on the bottom of the page.</p>
      <hr>
      <h2 i18n>Support LionKat</h2>
      <p i18n>LionKat is free-to-use does not generate any profits. If you want to support us, you can buy us a coffee. (Yellow circle in the
        bottom right corner)
      <p>
    </div>
  </div>
  <lionkat-bottom-bar></lionkat-bottom-bar>
</div>
