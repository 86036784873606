<lionkat-menu *ngIf="withMenuButton"></lionkat-menu>

<h1 class="top-bar-title">{{text}}</h1>

<button class="hotkeys">
  <span class="material-icons"
        (click)="utility.openKeybindingsDialog()">
    keyboard
  </span>
</button>
