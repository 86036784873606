import {Component, OnInit} from '@angular/core';
import {CookieService} from '../../services/cookie.service';
import {Router} from '@angular/router';
import {CookieNamesEnum} from '../../classes/CookieNames.enum';

@Component({
  selector: 'lionkat-first-visit-page',
  templateUrl: './first-visit-page.component.html',
  styleUrls: ['./first-visit-page.component.scss']
})
export class FirstVisitPageComponent implements OnInit {

  detailed = false;

  acceptEverything = false;

  acceptMandatoryCookies = false;
  offlineMode = true;
  usingMatomo = true;

  constructor(private cookieService: CookieService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  enterLionkat() {
    if (!this.acceptEverything) {
      return;
    }
    this.cookieService.setCookie(CookieNamesEnum.acceptedCookies, true);
    this.router.navigateByUrl('');
  }

}
