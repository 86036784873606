<div id="outer-container">
  <div id="main">
    <div class="text-center">
      <h1 i18n>Welcome to Lionkat</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci architecto atque beatae blanditiis
        consectetur eos harum modi molestiae mollitia omnis, quos ratione, sequi tempora tempore, veritatis? Adipisci
        aut blanditiis commodi, dolor eos est ex iste, iure laboriosam nam neque non odio perspiciatis placeat provident
        quam qui sapiente ut velit voluptatibus.</p>
    </div>

    <p class="mt-5" style="color: var(--title-color)" i18n>LionKat uses cookies to provide core functionality,
      stores data in the browser cache to allow offline usability and uses Matomo Analytics
      services to monitor website usage.</p>

    <div *ngIf="!detailed">
      <p class="mb-1" i18n>I accept the use of cookies, storing data in browser cache.</p>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="acceptEverything">
        <span class="slider round"></span>
      </label>
      <div>
        <div class="text-center">
          <button class="lk-button mt-3" [disabled]="!acceptEverything" (click)="enterLionkat()" i18n>Enter LionKat</button>
          <hr>
          <p class="link" (click)="detailed = true; acceptEverything = true;" i18n>Change privacy preferences</p>
        </div>
      </div>
    </div>

    <div *ngIf="detailed">
      <div class="mt-3">
        <h2 i18n>Privacy preferences</h2>

<!--        <p class="mb-1">I accept the use of mandatory cookies:</p>-->
<!--        <label class="switch">-->
<!--          <input type="checkbox" [(ngModel)]="acceptMandatoryCookies">-->
<!--          <span class="slider round"></span>-->
<!--        </label>-->

        <p class="mb-1 mt-3" i18n>(Optional) Enable offline usage of LionKat by allowing sentence data to be stored
          in browser cache<br> (max. 2mb, can be turned off later)</p>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="offlineMode">
          <span class="slider round"></span>
        </label>

        <p class="mb-1 mt-3" i18n>(Optional) Enable Matomo Analytics. The only usage of Matomo Analytics is to see
          who visits the website, no ads are tailored to you, nor is your data sold.</p>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="usingMatomo">
          <span class="slider round"></span>
        </label>

        <div class="text-center">
          <button class="lk-button mt-3" [disabled]="!acceptEverything" (click)="enterLionkat()" i18n>Enter LionKat</button>
        </div>
      </div>
    </div>
  </div>
</div>
