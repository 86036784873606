import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PracticeHskComponent} from './components/practice-hsk/practice-hsk.component';
import {AboutComponent} from './components/about/about.component';
import {AcceptedCookiesGuard} from './guards/accepted-cookies.guard';
import {ImpressumComponent} from './components/impressum/impressum.component';

const routes: Routes = [
  /*{path: 'welcome', component: FirstVisitPageComponent, canActivate: [NotYetVisitedGuard], title: 'Welcome - LionKat'},*/
  {path: 'about', component: AboutComponent, title: 'About - LionKat'},
  {path: 'impressum', component: ImpressumComponent, title: 'Impressum - LionKat'},
  {path: '', component: PracticeHskComponent, canActivate: [AcceptedCookiesGuard], title: 'Practice Mandarin - LionKat'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
