import { Injectable } from '@angular/core';
import {BugDialogComponent} from '../components/bug-dialog/bug-dialog.component';
import {DataService} from './data.service';
import {KeybindingsDialogComponent} from '../components/keybindings-dialog/keybindings-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private data: DataService) { }

  openBugDialog() {
    this.data.listenToKeystrokes = false;
    const dialogRef = this.data.dialog.open(BugDialogComponent, {panelClass: 'larger-mat-dialog'});
    dialogRef.afterClosed().subscribe(() => {
      this.data.listenToKeystrokes = true;
    });
  }

  openKeybindingsDialog() {
    this.data.listenToKeystrokes = false;
    const dialogRef = this.data.dialog.open(KeybindingsDialogComponent, {panelClass: 'larger-mat-dialog'});
    dialogRef.afterClosed().subscribe(() => {
      this.data.listenToKeystrokes = true;
    });
  }
}
