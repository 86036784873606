import {Injectable} from '@angular/core';
import {CookieNamesEnum} from '../classes/CookieNames.enum';

@Injectable({
  providedIn: 'root'
})
export class CookieService {


  /**
   * returns the specified cookie or an empty string if it does not exist.
   * @param cname Cookie name.
   */
  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   * Sets (or overrides if exists) a cookie with specified name and value and a very large expiration date.
   * @param cname Cookie name.
   * @param cvalue Cookie value.
   */
  setCookie(cname, cvalue) {
    document.cookie = cname + '=' + cvalue + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }


  deleteCookie(cname) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  deleteAllSettingsCookies() {
    this.deleteCookie(CookieNamesEnum.font);
    this.deleteCookie(CookieNamesEnum.colorTheme);
    this.deleteCookie(CookieNamesEnum.showTranslation);
    this.deleteCookie(CookieNamesEnum.showTooltips);
    this.deleteCookie(CookieNamesEnum.saveCurrentSentenceId);
    this.deleteCookie(CookieNamesEnum.currentSentenceId);
    this.deleteCookie(CookieNamesEnum.currentHSKFilter);
  }
}
