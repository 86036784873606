import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {CookieService} from '../services/cookie.service';
import {CookieNamesEnum} from '../classes/CookieNames.enum';

@Injectable({
  providedIn: 'root'
})
export class AcceptedCookiesGuard implements CanActivate {

  constructor(private router: Router,
              private cookieService: CookieService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true; // maybe some day
    if (this.cookieService.getCookie(CookieNamesEnum.acceptedCookies)) {
      return true;
    } else {
      this.router.navigateByUrl('/welcome');
      return false;
    }
  }
}
