import { Component } from '@angular/core';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'lionkat-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent {

  constructor(public utility: UtilityService) { }
}
