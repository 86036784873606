<button id="menu-button" (click)="toggleMenu()">
      <span class="material-icons">
        menu
      </span>
</button>

<div id="menu-container" [class.hidden-menu-container]="!data.menu" (click)="toggleMenu()">
</div>
<div id="menu" [class.hidden-menu]="!data.menu" (click)="this.data.menu = true">
  <h1 class="mt-2" i18n>Settings</h1>
  <hr>

  <div class="menu-section">
    <h2 class="menu-h2" i18n>App language</h2>
    <div class="d-inline-block text-center">
      <img src="assets/usa.jpg" class="language-image mr-2" i18n-alt alt="usa" [class.active]="locale === Languages.en"
           (click)="changeLanguage(Languages.en)">
      <img src="assets/germany.jpg" class="language-image" i18n-alt alt="germany"
           [class.active]="locale === Languages.de"
           (click)="changeLanguage(Languages.de)">
    </div>
  </div>
  <hr>

  <!-- Sentence pool -->
  <!--  <ng-container>
      <div class="menu-section">
        <h2 class="menu-h2" i18n>Sentence source</h2>
        <div class="row">
          <div class="col-6">
            <button class="lk-button w-100 active" i18n>HSK 1-4</button>
          </div>
          <div class="col-6">
            <button class="lk-button w-100" disabled i18n>Themed categories</button>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>-->

  <!-- Font -->
  <div class="menu-section">
    <h2 class="menu-h2" i18n>Mandarin Font</h2>
    <div class="row">
      <div class="col-12 col-md-4 mb-2 mb-md-0" *ngFor="let font of data.fonts; let i = index">
        <button class="lk-button menu-font-button"
                [class.ma-shan-zheng]="font === MandarinFonts.MaShanZheng"
                [class.noto-serif]="font === MandarinFonts.NotoSerif"
                [class.noto-sans]="font === MandarinFonts.NotoSans"
                [class.active]="font === userService.font"
                (click)="changeFont(font)">
          今天很冷
        </button>
      </div>
    </div>
  </div>
  <hr>

  <!-- color theme -->
  <!--    <div class="menu-section">
        <h2 class="menu-h2" i18n>Theme (more soon)</h2>
        <div class="row">
          <div class="col-12 col-md-3 mb-1 mb-md-0" *ngFor="let theme of data.colorThemes">
            <button class="{{theme}}"
                    [class.active]="userService.colorTheme === theme"
                    [disabled]="theme !== ColorThemes.Dark"
                    (click)="changeColorTheme(theme)">
              {{theme}}
            </button>
          </div>
        </div>
      </div>-->
  <hr>

  <!-- always show translation -->
  <div class="menu-section">
    <h2 class="menu-h2 mr-2" i18n>Always show translation</h2>
    <label class="switch">
      <input type="checkbox" [ngModel]="userService.alwaysShowTrans" (change)="toggleShowTranslation()">
      <span class="slider round"></span>
    </label>
  </div>
  <hr>

  <!-- show tooltips -->
  <ng-container *ngIf="false">
    <div class="menu-section">
      <h2 class="menu-h2 mr-2" i18n>Show tooltips</h2>
      <label class="switch">
        <input type="checkbox" [ngModel]="userService.showTooltips" (change)="toggleShowTooltips()">
        <span class="slider round"></span>
      </label>
    </div>
    <hr>
  </ng-container>

  <!-- saveSentenceId -->
  <ng-container *ngIf="false">
    <div class="menu-section">
      <h2 class="menu-h2 mr-2" i18n>Remember current sentence and filter</h2>
      <label class="switch">
        <input type="checkbox" [ngModel]="userService.saveCurrentSentenceId" (change)="toggleSaveCurrentSentenceId()">
        <span class="slider round"></span>
      </label>
    </div>
    <hr>
  </ng-container>

  <!-- reset -->
  <div class="menu-section">
    <h2 class="menu-h2 mr-2" i18n>Reset all settings</h2>
    <button class="lk-button" (click)="openResetSettingsDialog()" i18n>Reset</button>
    <h2 style="color: #169116" *ngIf="data.settingsReset" i18n>Settings succesfully reset.</h2>
  </div>
  <hr>
</div>
