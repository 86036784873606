import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Sentence} from '../classes/Sentence';
import {Feedback} from '../classes/Feedback';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  adress = 'https://api.lionkat.app';

  // adress = 'http://localhost:30000';

  constructor(private http: HttpClient) {
  }

  getAllHSKSentences(): Observable<Sentence[]> {
    return this.http.get<Sentence[]>(`${this.adress}/getAllHSKSentences`);
  }


  submitFeedback(f: Feedback): Observable<any> {
    return this.http.post(`${this.adress}/submitFeedback`, f);
  }
}
