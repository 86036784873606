import { Component } from '@angular/core';

@Component({
  selector: 'lionkat-keybindings-dialog',
  templateUrl: './keybindings-dialog.component.html',
  styleUrls: ['./keybindings-dialog.component.scss']
})
export class KeybindingsDialogComponent {

  constructor() { }
}
