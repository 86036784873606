import {Component} from '@angular/core';
import {Feedback} from '../../classes/Feedback';
import {UserService} from '../../services/user.service';
import {DataService} from '../../services/data.service';
import {ApiService} from '../../services/api.service';
import {BugDialogSteps} from '../../classes/BugDialogSteps';

@Component({
  selector: 'lionkat-bug-dialog',
  templateUrl: './bug-dialog.component.html',
  styleUrls: ['./bug-dialog.component.scss']
})
export class BugDialogComponent {
  BugDialogSteps = BugDialogSteps;
  loading = false;
  step = BugDialogSteps.Overview;
  maxDescLen = 2000;
  errorMessages = [];
  serverErrorMessage: string;
  feedback: Feedback = new Feedback();

  constructor(private userService: UserService,
              private api: ApiService,
              private data: DataService) {
  }

  backToMain() {
    this.errorMessages = [];
    this.feedback = new Feedback();
    this.step = BugDialogSteps.Overview;
  }

  onInputChange(value) {
    this.feedback.reason = value;
  }

  submitFeedback() {
    this.errorMessages = [];

    if (this.step === 'sentence') {
      if (!this.feedback.reason) {
        const errFeedback = $localize`One reason must be selected.`;
        this.errorMessages.push(errFeedback);
      } else if (!['bad', 'faulty', 'other'].includes(this.feedback.reason)) {
        const errFeedback2 = $localize`Select a valid reason.`;
        this.errorMessages.push(errFeedback2);
      }
    }
    if (!this.feedback.description || this.feedback.description.length === 0) {
      const errDesc = $localize`Description cannot be empty.`;
      this.errorMessages.push(errDesc);
    } else if (this.feedback.description.length > this.maxDescLen) {
      const errDescLen = $localize`Description must be less than ${this.maxDescLen} characters.`;
      this.errorMessages.push(errDescLen);
    }

    if (this.errorMessages.length === 0) {
      this.loading = true;
      this.feedback.sentence_id = this.data.hskSentences[this.data.currentSentencePos].id;
      this.api.submitFeedback(this.feedback).subscribe({
        next: () => {
          this.step = BugDialogSteps.Thanks;
        },
        error: err => {
          this.serverErrorMessage = err.error;
          this.step = BugDialogSteps.Error;
        },
      }).add(() => {
        this.loading = false;
      });
    }
  }
}
